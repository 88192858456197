import React, { ComponentType, FC, ReactNode } from "react";

import { useMediaQuery } from "react-responsive";

import {
    type PropsOf,
    css,
    ThemeProvider as ThemeProvider_,
} from "@emotion/react";
import type { CSSInterpolation, SerializedStyles } from "@emotion/serialize";
import color from "color";

const toEntries = <Rec extends Record<string, any>>(
    rec: Rec
): [keyof Rec, Rec[keyof Rec]][] => Object.entries(rec) as any;
const fromEntries = <k extends string, v>(vs: [k, v][]): Record<k, v> =>
    Object.fromEntries(vs) as any;

const baseColors = {
    red: color("#e60028"),
    green: color("#26bd00"),
    blue: color("#05235f"),
    grey: color("#f0f0f0"),
    darkGrey: color("#b6b6b6"),
    white: color("#ffffff"),
    black: color("#000000"),
};

export enum Color {
    primary = "primary",
    secondary = "secondary",
}
const colorMap: Record<Color, keyof typeof baseColors> = {
    [Color.primary]: "red",
    [Color.secondary]: "blue",
};
type AnyColor = keyof typeof baseColors | Color;
const colors: Readonly<
    Record<AnyColor, color> & { contrast: Record<AnyColor, color> }
> = {
    ...baseColors,
    get [Color.primary]() {
        return baseColors[colorMap[Color.primary]];
    },
    get [Color.secondary]() {
        return baseColors[colorMap[Color.secondary]];
    },
    contrast: {
        get red() {
            return colors.white;
        },
        get green() {
            return colors.white;
        },
        get blue() {
            return colors.white;
        },
        get grey() {
            return colors.black;
        },
        get darkGrey() {
            return colors.white;
        },
        get white() {
            return colors.black;
        },
        get black() {
            return colors.white;
        },
        get [Color.primary]() {
            return colors.contrast[colorMap[Color.primary]];
        },
        get [Color.secondary]() {
            return colors.contrast[colorMap[Color.secondary]];
        },
    },
};

export enum Breakpoint {
    extraSmall = "extraSmall",
    small = "small",
    medium = "medium",
    normal = "normal",
    large = "large",
    extraLarge = "extraLarge",
}
type BreakpointFrom = Exclude<Breakpoint, Breakpoint.extraSmall>;
type BreakpointUpTo = Exclude<Breakpoint, Breakpoint.extraLarge>;
type CssObj = {
    css: typeof css;
};
type CssMediaObj = CssObj & {
    useMatches: () => boolean;
    Matches: ComponentType<{ children: ReactNode }>;
};
const cssNoBreakpoint: CssMediaObj = {
    css,
    useMatches: () => true,
    Matches: (({ children }) => children) as FC<{ children: ReactNode }>,
};

export const breakpointSteps: { name: Breakpoint; width: number }[] = [
    { name: Breakpoint.extraSmall, width: 600 },
    { name: Breakpoint.small, width: 960 },
    { name: Breakpoint.medium, width: 1264 },
    { name: Breakpoint.normal, width: 1750 },
    { name: Breakpoint.large, width: 1980 },
    { name: Breakpoint.extraLarge, width: 100000 },
];

const getBreakpoint = (target: Breakpoint) => {
    const v = breakpointSteps.find(({ name }) => name === target);
    if (v === undefined) throw new Error("getBreakpoint must be total");
    return v;
};

const getPrevBreakpoint = (target: BreakpointFrom) => {
    const i = breakpointSteps.findIndex(({ name }) => name === target);
    if (i === -1) throw new Error("getPrevBreakpoint must be total");
    if (i === 0) return { name: "noWidth", width: 0 };
    return breakpointSteps[i - 1];
};

const mkMedia = (query: string): CssMediaObj => ({
    css: ((...args: CSSInterpolation[]) =>
        css`
            @media ${query} {
                ${css(...args)}
            }
        `) as any,
    useMatches: () =>
        useMediaQuery({
            query,
        }),
    Matches: (({ children }) => {
        const matches = useMediaQuery({
            query,
        });

        return matches ? children ?? null : null;
    }) as FC<{ children: ReactNode }>,
});
const media = {
    mkMedia,
    fromWidth: (w: number) => mkMedia(`(min-width: ${w}px)`),
    upToWidth: (w: number) => mkMedia(`(max-width: ${w - 1}px)`),
    betweenWidth: (wMin: number, wMax: number) =>
        media.mkMedia(`(min-width: ${wMin}px) and (max-width: ${wMax - 1}px)`),
    hover: {
        primary: Object.assign(mkMedia("(hover: hover)"), {
            none: mkMedia("(hover: none)"),
        }),
        any: Object.assign(mkMedia("(any-hover: hover)"), {
            none: mkMedia("(any-hover: none)"),
        }),
    },
    pointer: {
        primary: {
            coarse: mkMedia("(pointer: coarse)"),
            fine: mkMedia("(pointer: fine)"),
            none: mkMedia("(pointer: none)"),
        },
        any: {
            coarse: mkMedia("(any-pointer: coarse)"),
            fine: mkMedia("(any-pointer: fine)"),
            none: mkMedia("(any-pointer: none)"),
        },
    },
};

const breakpoint = {
    upTo: (name: Breakpoint) =>
        name === Breakpoint.extraLarge
            ? cssNoBreakpoint
            : media.upToWidth(getBreakpoint(name).width),
    from: (name: Breakpoint) =>
        name === Breakpoint.extraSmall
            ? cssNoBreakpoint
            : media.fromWidth(getPrevBreakpoint(name).width),
    between: (min: Breakpoint, max: Breakpoint) =>
        min === Breakpoint.extraSmall && max === Breakpoint.extraLarge
            ? cssNoBreakpoint
            : min === Breakpoint.extraSmall
            ? breakpoint.upTo(max)
            : max === Breakpoint.extraLarge
            ? breakpoint.from(min)
            : media.betweenWidth(
                  getPrevBreakpoint(min).width,
                  getBreakpoint(max).width
              ),
};

const perBreakpoint =
    (
        mediaQuery: (name: Breakpoint) => CssMediaObj,
        localBreakpointSteps: typeof breakpointSteps
    ) =>
    (styles: Record<Breakpoint, CSSInterpolation>) =>
        css(
            ...localBreakpointSteps
                .map(({ name }) => [name, styles[name]] as const)
                .filter(([, style]) => style)
                .map(([name, style]) => mediaQuery(name).css(style))
        );

const mapRecord =
    <a, b, k extends string>(f: (a: a, k: k, o: Record<k, a>) => b) =>
    (o: Record<k, a>): Record<k, b> =>
        fromEntries(toEntries(o).map(([k, v]) => [k, f(v, k, o)]));

type CSSPropVal = null | undefined | boolean | number | string;

const propPerBreakpoint =
    (...args: Parameters<typeof perBreakpoint>) =>
    (prop: string, values: Record<Breakpoint, CSSPropVal>) =>
        perBreakpoint(...args)(
            mapRecord(
                (v: CSSPropVal) =>
                    css`
                        ${prop}: ${v};
                    `
            )(values)
        );

const normaliseBreakpointRecord_ =
    (localBreakpointSteps: typeof breakpointSteps) =>
    <b,>(
        rec: Partial<Record<Breakpoint, b>>
    ): Record<Breakpoint, NonNullable<b>> =>
        fromEntries(
            localBreakpointSteps
                .map(
                    ({ name }, i): [Breakpoint, NonNullable<b>] | undefined => {
                        if (rec[name] != null)
                            return [name, rec[name] as NonNullable<b>];
                        const res = breakpointSteps
                            .slice(i + 1)
                            .find(({ name }) => rec[name] != null);
                        if (res) return [name, rec[res.name] as NonNullable<b>];
                        return undefined;
                    }
                )
                .filter((v): v is NonNullable<typeof v> => v != null)
        );

const normaliseBreakpointRecord = <b,>(rec: Partial<Record<Breakpoint, b>>) =>
    normaliseBreakpointRecord_(breakpointSteps)(
        normaliseBreakpointRecord_(breakpointSteps.slice().reverse())(rec)
    );

const zipWithBreakpointRecords_ =
    <a, b, c>(f: (a: a, b: b) => c) =>
    (as: Record<Breakpoint, a>, bs: Record<Breakpoint, b>) =>
        mapRecord<a, c, Breakpoint>((v, k) => f(v, bs[k]))(as);

const breakpoints = {
    from: fromEntries<BreakpointFrom, CssMediaObj>(
        breakpointSteps
            .slice(1)
            .map(({ name }) => [name as BreakpointFrom, breakpoint.from(name)])
    ),
    perEachFrom: perBreakpoint(breakpoint.from, breakpointSteps),
    propPerEachFrom: propPerBreakpoint(breakpoint.from, breakpointSteps),
    upTo: fromEntries<BreakpointUpTo, CssMediaObj>(
        breakpointSteps
            .slice(0, -1)
            .map(({ name }) => [name as BreakpointUpTo, breakpoint.upTo(name)])
    ),
    perEachUpTo: perBreakpoint(
        breakpoint.upTo,
        breakpointSteps.slice().reverse()
    ),
    propPerEachUpTo: propPerBreakpoint(
        breakpoint.upTo,
        breakpointSteps.slice().reverse()
    ),
    zipWithBreakpointRecords:
        <a, b, c>(f: (a: a, b: b) => c) =>
        (
            as: Partial<Record<Breakpoint, a>>,
            bs: Partial<Record<Breakpoint, b>>
        ) =>
            zipWithBreakpointRecords_(f)(
                normaliseBreakpointRecord(as),
                normaliseBreakpointRecord(bs)
            ),
};

export const breakpointPaddings = {
    containerInner: {
        [Breakpoint.extraSmall]: "10px",
        [Breakpoint.small]: "20px",
        [Breakpoint.medium]: "50px",
        [Breakpoint.normal]: "100px",
        [Breakpoint.large]: "150px",
        [Breakpoint.extraLarge]: "200px",
    },
    containerOuter: {
        [Breakpoint.extraSmall]: "20px",
        [Breakpoint.small]: "30px",
        [Breakpoint.medium]: "40px",
        [Breakpoint.normal]: "80px",
        [Breakpoint.large]: "176px",
        [Breakpoint.extraLarge]: "500px",
    },
    containerOuterInner: {} as Record<Breakpoint, string>,
};

breakpointPaddings.containerOuterInner = breakpoints.zipWithBreakpointRecords(
    (a, b) => `calc(${a} + ${b})`
)(breakpointPaddings.containerOuter, breakpointPaddings.containerInner);

const paddings = mapRecord(
    (
        values: (typeof breakpointPaddings)[keyof typeof breakpointPaddings],
        _: keyof typeof breakpointPaddings
    ) =>
        Object.assign(
            fromEntries(
                (["top", "right", "bottom", "left", "all"] as const).map(
                    (k) => [
                        k,
                        breakpoints.propPerEachFrom(
                            k === "all" ? "padding" : `padding-${k}`,
                            values
                        ),
                    ]
                )
            ),
            { values }
        )
)(breakpointPaddings);

const mkCss = (f: (style: SerializedStyles) => SerializedStyles): CssObj => ({
    css: ((...args: CSSInterpolation[]) => f(css(...args))) as any,
});

const selectors = {
    active: mkCss(
        (style) => css`
            &.active {
                ${style}
            }
        `
    ),

    hover: mkCss(
        (style) =>
            media.hover.primary.css`
                &:hover {
                    ${style}
                }
            `
    ),

    hoverOrActive: {
        css: ((...args: CSSInterpolation[]): SerializedStyles => css`
            ${selectors.active.css(...args)}
            ${selectors.hover.css(...args)}
        `) as any,
    } as CssObj,
};

const font = {
    families: {
        vito: "var(--font-family-vito)",
    },
};

const theme = {
    colors,
    media,
    breakpoint,
    breakpoints,
    paddings,
    selectors,
    font,
};
export type Theme = typeof theme;

const ThemeProvider = (props: Omit<PropsOf<ThemeProvider_>, "theme">) => (
    <ThemeProvider_ {...{ theme, ...props }} />
);
export default ThemeProvider;
